import React, { ChangeEvent, Fragment } from 'react'
import { H2, H4, H6, CopyRights, Paragraph2, Paragraph1 } from '@styles/Global.styles';

import { Record } from 'immutable'
import { Dispatch } from 'redux';
import { currentBasketSelector } from '@redux/selectors/basketSelector';
import { connect } from 'react-redux';
import { BasketLineResponseModel } from '@models/Basket/BasketLineResponseModel';
import { Grid, Icon, Responsive, Label, Loader } from 'semantic-ui-react';
import { PlateImageTitle, PlateImageSize, PlateImage } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles';
import { buildPlateImageInfoFromBasket } from '@utils/Helpers';

import { deleteBasketLineRequest } from '@redux/actions/deleteBasketLine';
import { BasketResponseSummary } from '@models/Basket/BasketResponseModel';
import { KpButton } from '@elements/index';
import theme from '@styles/theme';
import { navigate } from 'gatsby';
import { updateIsLayby, updateManufactureNow, updateOwnerIsApplicant, updateIsMultiPlate, updateIsGift, updateIsAutopay, updateLaybyQuote, removePromotion, updatePaymentType, updateIsGifting, updateGiftingType } from '@redux/actions/checkout';
import { VehicleTypesSetting, TransactionTypesSetting } from '@models/ProductModels';
import DisableLaybyInfo from './DisableLaybyInfo';
import { validatePromoService } from '@services/BasketService';
import { refreshBasketRequest } from '@redux/actions/refreshBasket';
import InfoModal from '@components/InfoModal/InfoModal';
import { LaybyQuote } from '@models/index';

import {
    IconButton,
    CartHeadingRow,
    PlateDetailSection,
    PlateDetailSectionHeading,
    HeadingRangeName,
    PlateDetailSectionSize,
    PlateDetailsGroup,
    PlateDetailsGroupLabel,
    PlateImageContainer,
    PlateDetailsGroupField,
    PlateImageSectionGridColumn,
    PlateImageSectionHeading,
    CartListGrid,
    ShoppingCartWrapper,
    CartLineItemRow,
    CombinationNumber,
    PlateItemPrice,
    PlateItemButtonGroup,
    CartSummaryGridSection,
    PromotionWrapper,
    PromotionLabel,
    PromotionField,
    SummaryActionGrid,
    SummaryActionRow,
    SummaryTotal,
    PromoInput,
    SummaryActionColumn,
    CartItemDeleteButton,
    PopupContainer,
    PopupHeading,
    PopupContent,
    DeletePopup,
    PurchaseTypeName,
    PlateItemPriceComputer,
    PromotionCodes,
    EmptyCartContainer,
    EmptyCartImg,
    UnavailableWrapper,
    UnavailableMessage,
    ShoppingCartItemImageSection
} from './ShoppingCart.styles'
import { AfterpayMessage } from '@utils/Afterpay';
import { getCurrentBasket } from '@redux/localStorage/basket';
import { getCurrentCheckout, saveAllowAfterToLocalStorage } from '@redux/localStorage/checkout';
import { matchTagRegex } from '@utils/Helpers';
import { CombinationPaymentList } from '@components/CombinationPricingOptions/CombinationPaymentList';


const emptyCartImg = require('@assets/images/cart-empty.png')

interface ShoppingCartProps {
    title: string
    cartId: string
    cartLineItems: BasketLineResponseModel[]
    summary: BasketResponseSummary
    loading: boolean
    success: boolean
    unavailableTitle: string
    unavailableMessage: string
    apiUrl: string
    delete: (apiUrl: string, basketId: string, basketLineId: string) => void
    updateIsLayby: Function
    updateIsAutopay: Function
    updateLaybyQuote: Function
    updateManufactureNow: Function
    updateOwnerIsApplicant: Function
    updateIsMultiPlate: Function
    updateIsGift: Function
    updatePaymentType: Function
    refreshBasket: Function
    removePromotion: Function
    basketIdFromUrl: string | undefined
    flags?: any
    updateIsGifting: Function
    updateGiftingType: Function
    darkTheme: boolean
}

interface ShoppingCartStates {
    promoCode: string,
    promoValidationError: string
    redemptionMessage: string
    validatingPromo: boolean
    cartLoaded: boolean
}

class ShoppingCartView extends React.Component<ShoppingCartProps, ShoppingCartStates>{
    constructor(props: ShoppingCartProps) {
        super(props);
        this.state = {
            promoCode: '',
            promoValidationError: '',
            redemptionMessage: '',
            validatingPromo: false,
            cartLoaded: false
        }
    }
    componentDidMount() {
        if (this.props.basketIdFromUrl) {
            this.props.refreshBasket(this.props.apiUrl, this.props.basketIdFromUrl)
        } else {
            if (this.props.cartId) {
                this.props.refreshBasket(this.props.apiUrl, this.props.cartId)
            }
        }
    }


    deleteBasketLine = (basketLineId: string) => {
        this.props.delete(this.props.apiUrl, this.props.cartId, basketLineId);
    }
    editBasketLine = (basketLineId: string) => {
        const basketLine = this.props.cartLineItems.find(c => c.basketLineId === basketLineId);
        var url = `/select-a-style/?combination=${basketLine?.combination}&tt=${basketLine?.transactionTypeId}&vt=${basketLine?.vehicleTypeId}&productid=${basketLine?.frontPlate.designId}`
        navigate(url, {
            state: {
                basketLine: basketLine
            }
        })
    }
    applyPromotionCode = () => {
        if (matchTagRegex(this.state.promoCode)) {
            return
        }
        this.setState({ validatingPromo: true });
        validatePromoService(this.props.apiUrl, this.state.promoCode, this.props.cartId).then((data: any) => {
            if (data.IsValid) {
                saveAllowAfterToLocalStorage('true')
                this.setState({ redemptionMessage: data.RedemptionMessage })
                this.props.refreshBasket(this.props.apiUrl, this.props.cartId);
                this.setState({ validatingPromo: false });
            } else {
                saveAllowAfterToLocalStorage('')
                this.setState({ promoValidationError: data.Reason, validatingPromo: false });
            }
        })

    }
    setPromoCode = (event: ChangeEvent, data: any) => {
        this.setState({ promoCode: data.value });
    }
    startGiftingJourney = () => {
        let isGift = false;
        this.props.cartLineItems.forEach((cartLineItem) => {
            if (cartLineItem.isGift) {
                isGift = true;
                this.props.updateManufactureNow(false);
                this.props.updateOwnerIsApplicant(false);
            }
        })
        this.props.updateIsGift(isGift);
        return isGift;
    }
    startRestyleJourney = () => {
        let isRestyle = false;
        this.props.cartLineItems.forEach((cartLineItem) => {
            if (cartLineItem.transactionTypeId === 2 || cartLineItem.transactionTypeId === 3) {
                isRestyle = true;
            }
        })
        return isRestyle;
    }
    startMultiplateJourney = () => {
        const isMultiPlate = this.props.cartLineItems.length > 1;
        if (isMultiPlate) {
            this.props.updateIsMultiPlate(true);
            this.props.updateOwnerIsApplicant(true);
            this.props.updateManufactureNow(false);
        }

        return isMultiPlate;
    }
    startLaybyJourney = () => {
        this.props.updateIsLayby(true);
        this.props.updatePaymentType(1);
        this.props.updateIsAutopay(false);
        this.props.updateLaybyQuote({
            bracketName: 'Monthly',
            installmentFrequency: 28
        });
        this.props.updateManufactureNow(false);
        this.props.updateOwnerIsApplicant(true);
        this.props.updateIsMultiPlate(false);
        if (this.startGiftingJourney()) {
            navigate('/personal-details/')
        }
        else {
            navigate('/plate-owner-selection/');
        }
    }
    startFullPaymentJourney = () => {
        this.props.updateIsLayby(false);
        this.props.updateManufactureNow(false);
        this.props.updateOwnerIsApplicant(true);
        this.props.updateIsMultiPlate(false);
        this.props.updateIsGifting(false);
        this.props.updateGiftingType(0);
        const checkout = getCurrentCheckout()

        if (this.startMultiplateJourney()) {
            navigate('/personal-details/')
        }
        else if (this.startGiftingJourney()) {
            navigate('/personal-details/')
        }
        else if (checkout.isDealerOrder) {
            if(this.startRestyleJourney()){
                this.props.updateManufactureNow(true);
            }
            navigate('/plate-manufacture-selection/')
        }
        else {
            navigate('/plate-owner-selection/');
        }
    }

    getPromoCodeValidationError(defaultMessage: string) {

        let result = {
            title: 'Unsuccessful',
            message: defaultMessage
        }

        if (this.props.summary.promotionCode !== '') {
            let itemsInvalid = true;

            this.props.cartLineItems.forEach((lineItem) => {
                if (lineItem.discountedPriceInclGst !== null) {
                    itemsInvalid = false;
                }
            });

            if (itemsInvalid) {
                result = {
                    title: 'Unsuccessful',
                    message: 'Promotion code applied, however no items in this cart qualified for a discount.'
                }
            }
            else {
                result = {
                    title: 'Thank you',
                    message: 'The discount has been applied to your order'
                }
            }
        }

        return result;
    }
    showLaybyButton = (cartItems: BasketLineResponseModel[]) => {
        //HIDE LAYBY IF ALL ITEMS ARE UPGRADE OR REDESIGN (1 OR MORE)
        let show = false;
        for (let item of cartItems) {
            if (item.transactionTypeId === TransactionTypesSetting.New.id) {
                show = true;
                break;
            }
        }
        return show;
    }
    renderInfoModal = (title: string, content: string, closeModal: Function) => {
        return <InfoModal modalOpen={true} title={title} content={content} onClose={closeModal} />
    }
    resetPromoValidationError = () => this.setState({ promoValidationError: '' })
    resetPromoValidationSuccess = () => this.setState({ redemptionMessage: '' })
    resetPromoValidation = () => this.setState({ promoValidationError: '', redemptionMessage: '' })
    resetPromotion = () => {
        this.props.removePromotion(this.props.apiUrl, this.props.cartId);
        setTimeout(() => {
            this.setState({cartLoaded: true})
        }, 1000);
    }
    render() {
        const { title, cartLineItems, summary, apiUrl, unavailableTitle, unavailableMessage, loading, basketIdFromUrl,darkTheme } = this.props;
        //const restylePlate = cartLineItems.find((lineItem) => {return lineItem.transactionTypeId === 2 || lineItem.transactionTypeId === 3});
        const hasItemUnavailable = cartLineItems.find(i => i.stillAvailable === false) !== undefined;
        const checkout = getCurrentCheckout()
        return (
            <ShoppingCartWrapper darkTheme={darkTheme}>
                {
                    ((this.state.promoCode && this.state.promoValidationError !== '') || this.state.redemptionMessage !== '') &&
                    this.renderInfoModal(this.getPromoCodeValidationError(this.state.promoValidationError).title, this.getPromoCodeValidationError(this.state.promoValidationError).message, this.resetPromoValidation)
                }

                {
                    cartLineItems && cartLineItems.length > 0 ?
                        <Fragment>
                            <CartListGrid>
                                <H2>{title}</H2>
                                <Responsive as={CartHeadingRow} minWidth={Responsive.onlyComputer.minWidth}>
                                    <Grid.Column width={10}>
                                        <H6>Design</H6>
                                    </Grid.Column>
                                    <Grid.Column width={3} textAlign="left">
                                        <H6>
                                            Combination
                                        </H6>
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="left">
                                        <H6>
                                            Price
                                        </H6>
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign="right">
                                    </Grid.Column>
                                </Responsive>
                                {
                                    this.props.cartLineItems.map((item, key) => {
                                        const info = buildPlateImageInfoFromBasket(apiUrl, item);
                                        const available = item.stillAvailable;
                                        return (
                                            <CartLineItemRow key={item.basketLineId} darkTheme={darkTheme}>
                                                <Grid.Column mobile={16} tablet={16} computer={10} widescreen={10} largeScreen={10}>
                                                    <Grid>
                                                        <PlateImagesBlock available={available} unavailableTitle={unavailableTitle} unavailableMessage={unavailableMessage}>

                                                            {
                                                                item.vehicleTypeId === VehicleTypesSetting.Car.id &&
                                                                <PlateImageSectionGridColumn mobile={16} tablet={8} computer={8} widescreen={8} largeScreen={8}>
                                                                    <div>
                                                                        <PlateImageSectionHeading>
                                                                            <PlateImageTitle>Front Plate</PlateImageTitle>
                                                                            <PlateImageSize>{item.vehicleTypeName}</PlateImageSize>
                                                                        </PlateImageSectionHeading>
                                                                        <PlateImageContainer>
                                                                            <PlateImage src={info.frontUrl} centered width={info.frontWidth} alt="front plate image" />
                                                                        </PlateImageContainer>
                                                                    </div>
                                                                    <div>
                                                                        <PlateDetailSection>
                                                                            <PlateDetailSectionHeading>
                                                                                <HeadingRangeName>
                                                                                    {item.frontPlate.rangeName}
                                                                                </HeadingRangeName>
                                                                                <PlateDetailSectionSize>
                                                                                    {item.frontPlate.size.width_mm}x{item.frontPlate.size.height_mm}mm
                                                                            </PlateDetailSectionSize>
                                                                            </PlateDetailSectionHeading>
                                                                            <PlateDetailsGroup>
                                                                                <PlateDetailsGroupLabel darkTheme={darkTheme}>
                                                                                    Plate Design
                                                                            </PlateDetailsGroupLabel>
                                                                                <PlateDetailsGroupField darkTheme={darkTheme}>
                                                                                    {item.frontPlate.designName}
                                                                                </PlateDetailsGroupField>
                                                                            </PlateDetailsGroup>
                                                                            <PlateDetailsGroup>
                                                                                <PlateDetailsGroupLabel darkTheme={darkTheme}>
                                                                                    Text Colour
                                                                            </PlateDetailsGroupLabel>
                                                                                <PlateDetailsGroupField darkTheme={darkTheme}>
                                                                                    {item.frontPlate.foregroundColorName}
                                                                                </PlateDetailsGroupField>
                                                                            </PlateDetailsGroup>
                                                                        </PlateDetailSection>
                                                                    </div>
                                                                </PlateImageSectionGridColumn>
                                                            }
                                                            <PlateImageSectionGridColumn mobile={16} tablet={8} computer={8} widescreen={8} largeScreen={8}>
                                                                <PlateImageSectionHeading>
                                                                    <PlateImageTitle>Back Plate</PlateImageTitle>
                                                                    <PlateImageSize>{item.vehicleTypeName}</PlateImageSize>
                                                                </PlateImageSectionHeading>
                                                                <PlateImageContainer>
                                                                    <PlateImage src={info.backUrl} centered width={info.backWith} alt="back plate image" />
                                                                </PlateImageContainer>
                                                                <PlateDetailSection>
                                                                    <PlateDetailSectionHeading>
                                                                        <HeadingRangeName>
                                                                            {item.backPlate.rangeName}
                                                                        </HeadingRangeName>
                                                                        <PlateDetailSectionSize>
                                                                            {item.backPlate.size.width_mm}x{item.backPlate.size.height_mm}mm
                                                                    </PlateDetailSectionSize>
                                                                    </PlateDetailSectionHeading>
                                                                    <PlateDetailsGroup>
                                                                        <PlateDetailsGroupLabel darkTheme={darkTheme}>
                                                                            Plate Design
                                                                    </PlateDetailsGroupLabel>
                                                                        <PlateDetailsGroupField darkTheme={darkTheme}>
                                                                            {item.backPlate.designName}
                                                                        </PlateDetailsGroupField>
                                                                    </PlateDetailsGroup>
                                                                    <PlateDetailsGroup>
                                                                        <PlateDetailsGroupLabel darkTheme={darkTheme}>
                                                                            Text Colour
                                                                    </PlateDetailsGroupLabel>
                                                                        <PlateDetailsGroupField darkTheme={darkTheme}>
                                                                            {item.backPlate.foregroundColorName}
                                                                        </PlateDetailsGroupField>
                                                                    </PlateDetailsGroup>
                                                                </PlateDetailSection>
                                                            </PlateImageSectionGridColumn>
                                                        </PlateImagesBlock>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={3} widescreen={3} largeScreen={3} textAlign="left">
                                                    <Responsive as={Grid} maxWidth={Responsive.onlyComputer.minWidth}>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Combination
                                                                </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    {item.combination}
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Responsive>
                                                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                                        <CombinationNumber>{item.combination}</CombinationNumber>
                                                        <PurchaseTypeName>{item.transactionTypeName}</PurchaseTypeName>
                                                    </Responsive>
                                                </Grid.Column>

                                                <Responsive as={Grid.Column} mobile={16} tablet={16} maxWidth={Responsive.onlyComputer.minWidth}>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Type
                                                                </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    {item.transactionTypeName}
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Grid>
                                                </Responsive>

                                                <Grid.Column mobile={16} tablet={16} computer={2} widescreen={2} largeScreen={2} textAlign="left">
                                                    <Responsive as={Grid} maxWidth={Responsive.onlyComputer.minWidth}>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Price
                                                                </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    <PlateItemPriceComputer available={available}>${item.priceInclGst.toFixed(2)}</PlateItemPriceComputer>
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Responsive>
                                                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                                        <PlateItemPrice available={available}>${item.priceInclGst.toFixed(2)}</PlateItemPrice>
                                                    </Responsive>

                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={1} widescreen={1} largeScreen={1} textAlign='right'>
                                                    <PlateItemButtonGroup icon>
                                                        <ShoppingCartDeleteConfirmation basketLineId={item.basketLineId} deleting={this.props.loading} deletedSuccess={this.props.success} deleteBasketLine={this.deleteBasketLine} />
                                                        {
                                                            available &&
                                                            <span id='sce'><IconButton onClick={() => this.editBasketLine(item.basketLineId)}>
                                                                <Icon name="pencil" color="green" />
                                                            </IconButton></span>
                                                        }

                                                    </PlateItemButtonGroup>
                                                </Grid.Column>
                                            </CartLineItemRow>
                                        )
                                    })
                                }
                            </CartListGrid>
                            <CartSummaryGridSection darkTheme={darkTheme}>
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={8} computer={9} largeScreen={9} widescreen={9}>
                                    {!checkout.isDealerOrder && <PromotionWrapper>
                                            <PromotionLabel darkTheme={darkTheme}>
                                                PROMO CODE
                                            </PromotionLabel>
                                            <PromotionField id='scpc'>
                                                <PromoInput action={{
                                                    content: 'APPLY',
                                                    onClick: this.applyPromotionCode
                                                }} onChange={this.setPromoCode} loading={this.state.validatingPromo} />
                                            </PromotionField>
                                            <CopyRights  darkTheme={darkTheme} color={theme.brand.colors.darkGrey}>
                                                Promo codes are not valid on Layby orders.
                                            </CopyRights>
                                            {
                                                summary.promotionCode &&
                                                <PromotionCodes>
                                                    <Label as='a' onClick={this.resetPromotion}>
                                                        {summary.promotionCode}
                                                        <Icon name="delete" />
                                                    </Label>
                                                </PromotionCodes>
                                            }
                                        </PromotionWrapper>}
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={7} largeScreen={7} widescreen={7}>
                                        <SummaryActionGrid>
                                            <SummaryActionRow>
                                                <Grid.Column width={8}>
                                                    <Paragraph2 color={darkTheme ? '#eee' : '#707070'}>
                                                        Manufacturing Fee
                                                    </Paragraph2>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <Paragraph2 color={darkTheme ? '#eee' : '#707070'}>
                                                        Included
                                                    </Paragraph2>
                                                </Grid.Column>
                                            </SummaryActionRow>
                                            <SummaryActionRow>
                                                <Grid.Column width={8}>
                                                    <Paragraph2 color={darkTheme ? '#eee' : '#707070'}>
                                                        Delivery
                                                    </Paragraph2>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <Paragraph2 color={darkTheme ? '#eee' : '#707070'}>
                                                        Free
                                                    </Paragraph2>
                                                </Grid.Column>
                                            </SummaryActionRow>
                                            {
                                                summary.discount !== 0 &&
                                                <Fragment>
                                                    <SummaryActionRow>
                                                        <Grid.Column width={8}>
                                                            Subtotal
                                                        </Grid.Column>
                                                        <Grid.Column width={8} textAlign="right">
                                                            ${summary.itemTotal.toFixed(2)}
                                                        </Grid.Column>
                                                    </SummaryActionRow>
                                                    <SummaryActionRow>
                                                        <Grid.Column width={8}>
                                                            Discount
                                                        </Grid.Column>
                                                        <Grid.Column width={8} textAlign="right">
                                                            -${summary.discount.toFixed(2)}
                                                        </Grid.Column>
                                                    </SummaryActionRow>
                                                </Fragment>
                                            }

                                            <SummaryActionRow darkTheme={darkTheme}>
                                                <Grid.Column width={8}>
                                                    <SummaryTotal darkTheme={darkTheme}>Total (incl GST)</SummaryTotal>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    <SummaryTotal darkTheme={darkTheme}>
                                                        ${summary.finalTotal.toFixed(2)}
                                                    </SummaryTotal>
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <CombinationPaymentList basePrice={summary.finalTotal}/>
                                                </Grid.Column>

                                            </SummaryActionRow>
                                        </SummaryActionGrid>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Responsive as={Grid.Column} minWidth={Responsive.onlyComputer.minWidth} computer={9} largeScreen={9} widescreen={9}>

                                    </Responsive>
                                    <Grid.Column mobile={16} tablet={16} computer={9} largeScreen={8} widescreen={8} floated='right'>
                                        <SummaryActionGrid>
                                            <SummaryActionRow>
                                                <SummaryActionColumn width={16} textAlign="right">
                                                    <span id="scpif"><KpButton id="scpif" fullWidth="mobile" buttonType='primary' disabled={hasItemUnavailable} color={theme.brand.colors.blue} onClick={this.startFullPaymentJourney} darkTheme={true}>
                                                        CHECKOUT
                                                    </KpButton></span>
                                                </SummaryActionColumn>
                                            </SummaryActionRow>
                                        </SummaryActionGrid>
                                    </Grid.Column>
                                </Grid.Row>
                            </CartSummaryGridSection>
                        </Fragment>
                        :
                        loading ?
                            <Loader active inline='centered'>Loading ...</Loader>
                            :
                            <EmptyCartContainer>
                                <EmptyCartImg src={emptyCartImg} desc="Kiwiplates - Empty Cart" />
                                <H4 textAlign="center">Your cart is empty</H4>
                                {this.props.basketIdFromUrl ?
                                <Paragraph1 color={theme.brand.colors.darkGrey} textAlign="center">
                                    Looks like the item not exists
                                </Paragraph1>
                                :
                                <Paragraph1 color={theme.brand.colors.darkGrey} textAlign="center">
                                    Looks like you haven't made your choice yet
                                </Paragraph1>}
                                <span id="gohome"><KpButton fullWidth="mobile" id="gohome" color={theme.brand.colors.green} buttonType='primary' link="/">Go Home</KpButton></span>
                            </EmptyCartContainer>
                }
            </ShoppingCartWrapper>
        )
    }
}

const mapStateToProps = (state: Record<any>) => {
    const cart = currentBasketSelector(state).toJS();
    return {
        cartId: cart.basketId,
        cartLineItems: cart.basketLines,
        summary: cart.summary,
        loading: cart.loading,
        success: cart.success
    };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    delete(apiUrl: string, basketId: string, basketLineId: string) {
        dispatch(deleteBasketLineRequest(apiUrl, basketId, basketLineId));
    },
    updateIsLayby(isLayby: boolean) {
        dispatch(updateIsLayby(isLayby))
    },
    updateManufactureNow(manufactureNow: boolean) {
        dispatch(updateManufactureNow(manufactureNow))
    },
    updateOwnerIsApplicant(ownerIsApplicant: boolean) {
        dispatch(updateOwnerIsApplicant(ownerIsApplicant))
    },
    updateIsMultiPlate(isMultiPlate: boolean) {
        dispatch(updateIsMultiPlate(isMultiPlate))
    },
    updateIsGift(isGift: boolean) {
        dispatch(updateIsGift(isGift))
    },
    updateIsAutopay(isAutopay: boolean) {
        dispatch(updateIsAutopay(isAutopay))
    },
    updatePaymentType(paymentType: number) {
        dispatch(updatePaymentType(paymentType))
    },
    updateLaybyQuote(laybyQuote: LaybyQuote) {
        dispatch(updateLaybyQuote(laybyQuote))
    },
    refreshBasket(apiUrl: string, basketId: string) {
        dispatch(refreshBasketRequest(apiUrl, basketId))
    },
    removePromotion(apiUrl: string, basketId: string) {
        dispatch(removePromotion(apiUrl, basketId))
    },
    updateIsGifting(isGifting:boolean){
        dispatch(updateIsGifting(isGifting));
    },
    updateGiftingType(giftingType: number) {
        dispatch(updateGiftingType(giftingType));
    }
})

interface ShoppingCartDeleteConfirmationProps {
    basketLineId: string
    deleteBasketLine: (basketId: string) => void
    deleting: boolean
    deletedSuccess: boolean
}
interface ShoppingCartDeleteConfirmationStates {
    open: boolean
}

class ShoppingCartDeleteConfirmation extends React.Component<ShoppingCartDeleteConfirmationProps, ShoppingCartDeleteConfirmationStates>{
    state = {
        open: false,
    }
    setOpen = (isOpen: boolean) => {
        this.setState({ open: isOpen });
    }
    deleteBasketLine = () => {
        this.props.deleteBasketLine(this.props.basketLineId);
    }
    render() {
        const { deleting } = this.props;
        return <DeletePopup
            trigger={<CartItemDeleteButton><Icon color="red" name="trash alternate outline" /></CartItemDeleteButton>}
            on='click'
            onClose={() => this.setOpen(false)}
            onOpen={() => this.setOpen(true)}
            open={this.state.open} positionFixed={true}>
            <PopupContainer>
                <PopupHeading>
                    Remove this plate?
                </PopupHeading>
                <PopupContent>
                    <Grid>
                        <Grid.Column width={8}>
                            <span id="scgo"><KpButton id="scgo" fullWidth="mobile tablet computer" buttonType="primary" loading={deleting} ignoreMinWidth={true} color={theme.brand.colors.blue} onClick={this.deleteBasketLine}>CONFIRM</KpButton></span>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <span id="scno"><KpButton id="scno" fullWidth="mobile tablet computer" buttonType="primary" disabled={deleting} color={theme.brand.colors.darkGrey} ignoreMinWidth={true} onClick={() => this.setOpen(false)}>CANCEL</KpButton></span>
                        </Grid.Column>
                    </Grid>
                </PopupContent>
            </PopupContainer>
        </DeletePopup>
    }

}

const PlateImagesBlock = ({ available, unavailableTitle, unavailableMessage, children }: { available: boolean, unavailableTitle: string, unavailableMessage: string, children: any }) => {
    return (
        <Fragment>
            {
                !available &&
                <UnavailableWrapper>
                    <UnavailableMessage>
                        <H4>{unavailableTitle}</H4>
                        <Paragraph1>{unavailableMessage}</Paragraph1>
                    </UnavailableMessage>
                </UnavailableWrapper>
            }
            {
                available ?
                    <Grid.Row>
                        {children}
                    </Grid.Row>
                    :
                    <ShoppingCartItemImageSection>
                        {children}
                    </ShoppingCartItemImageSection>
            }
        </Fragment>
    );
}

const ShoppingCartBlock = connect(mapStateToProps, mapDispatchToProps)(ShoppingCartView)
export default ShoppingCartBlock;
